import { Link } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import LibEnum from "lib/enum";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class EmployeeSaleRankingPage extends React.PureComponent {
  private summaryColumns = [];

  private columns: IViewTableColumn[] = [
    {
      title: '员工名称',
      dataIndex: 'employeeName',
      width: 200,
    },
    {
      title: '分管客户(家)',
      dataIndex: 'customerCount',
      align: 'center',
      sorter: (a: any, b: any) => a.customerCount - b.customerCount,
    },
    {
      title: '平均单价(元)',
      dataIndex: 'averagePrice',
      align: 'center',
      // sorter: (a: any, b: any) => a.averagePrice - b.averagePrice,
    },
    {
      title: '商品数量(主计量单位)',
      dataIndex: 'productCount',
      align: 'center',
      sorter: (a: any, b: any) => a.productCount - b.productCount,
    },
    {
      title: '订货金额(元)',
      dataIndex: 'saleAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.saleAmount - b.saleAmount,
    },
    {
      title: '成本金额(元)',
      dataIndex: 'purchaseAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.purchaseAmount - b.purchaseAmount,
    },
    {
      title: '利润(元)',
      dataIndex: 'profitAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.profitAmount - b.profitAmount,
    },
  ];
  render() {
    return <CommonReport
      showEmptyLabelText="仅显示有发生额的日期"
      filter={data => {
        return data.filter(i => (i.electricityAmount > 0 || i.waterAmount > 0 || i.oilAmount > 0 || i.salaryAmount > 0 || i.otherAmount > 0));
      }}
      tableColumns={this.columns}
      pagination={false}
      summaryColumns={this.summaryColumns}
      fetch={data => WebApi.report_employee_sale_ranking(data)}
    />
  }
}